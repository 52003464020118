import { API } from "aws-amplify";

export class YieldApi {
  static async getUsersHotelsMeta(): Promise<any> {
    return API.get("yield", `/hotels`, {});
  }

  static async bulkupdate(body: any) {
    return API.post("yield", "/bulkrates", { body });
  }

  static async publishrates(body: any) {
    return API.post("yield", "/publishrates", {
      body,
    });
  }

  static async removeSuggRate(body: any) {
    return API.put("yield", "/removeSuggestedRate", {
      body,
    });
  }

  static async suggestRate(body: any) {
    return API.put("yield", `/suggestedrate`, { body });
  }

  static async getSuggDays(
    currentHotelId: number,
    currentDate: string,
    twoYear: string,
  ): Promise<any> {
    return API.get(
      "yield",
      `/suggDays/${currentHotelId}/${currentDate}/${twoYear}`,
      {},
    );
  }

  static async getHotelConfig(hotelId: number): Promise<any> {
    return API.get("yield", `/hotel/${hotelId}`, {});
  }

  static async getDemandFromOTATable(
    otaHotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<any> {
    return await API.get(
      "yield",
      `/ota/${otaHotelId}/otaDemand/${startDate}/${endDate}`,
      {},
    );
  }

  static async getOtaHotelCompList(otaHotelId: number): Promise<any> {
    return await API.get("yield", `/ota/${otaHotelId}/competitors`, {});
  }

  static async getRatesFromOTATable(
    otaHotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<any> {
    return await API.get(
      "yield",
      `/ota/${otaHotelId}/otaRates/${startDate}/${endDate}`,
      {},
    );
  }
}
